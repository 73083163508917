@import url('https://fonts.googleapis.com/css2?family=Sora&family=Unbounded&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  display: none;
}

@keyframes spin-left {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(90deg);
  }
}

@keyframes spin-right {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(-90deg);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes share-appear {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes appear-below {
  from {
    height: 0%;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
}

.appear {
  animation: appear 0.5s;
}

.share-appear {
  animation: share-appear 0.5s;
}

.appear-below {
  animation: appear-below 0.5s;
}

.divide-under {
  border-bottom: 1px solid #d1d5db;
}

.active-tab {
  border-bottom: 1px solid #111827;
  transition: ease-in-out 0.2s;
}

.fade-in {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.7s, transform 0.5s;
}

.fade-in.visible {
  opacity: 1;
  transform: translateX(0);
}

.btn-shadow {
  box-shadow: 6px 6px black;;
}

.btn-select {
  outline: 2px solid black;
  outline-offset: 8px;
}

input, textarea {
text-decoration: blink;
}

.image-animate-left {
  animation: spin-left 4s linear infinite alternate;
  perspective: 200px;
  rotate: 5deg;
}

.image-animate-right {
  animation: spin-right 4s linear infinite alternate;
  perspective: 200px;
  rotate: -5deg;
}

.hero-img {
  height: 500px;
}